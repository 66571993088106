var btnSearch = $('.btn-search');
var btnSearchClose = $('.btn-search-close');
var barreDeNavigation = $('#barre-navigation');
var overlaySearch = $('#overlay-search');
var inputSearch = $('#search');


// Gestion de l'affichage de la barre de navigation qui doit remonter en haut grâce aux classes appliquées
th_overlay.addCallbackOpen(function (overlayId) {
    // on veut dans ce cas fermer tous les overlay ouvertes de la photothèque
    if (overlayId === 'overlay-search') {
        barreDeNavigation.addClass("is-open search-is-open");
        setTimeout(function () {
            inputSearch.focus()
        }, 200);

        inputSearch.parent().addClass('field-is-focus');
    }
});

th_overlay.addCallbackClose(function (overlayId) {
    // on veut dans ce cas fermer tous les overlay ouvertes de la photothèque
    if (overlayId === 'overlay-search') {
        barreDeNavigation.removeClass("is-open search-is-open");
    }
});


/* Script pour ajouter la classe search-is-scrolled sur le body */
overlaySearch.on('scroll', function (e) {
    var st = $(this).scrollTop();

    if (st > 100) {
        $("body").addClass("search-is-scrolled");
    } else {
        $("body").removeClass("search-is-scrolled");
    }
});


