this.initSlider = function () {
    var sliderContainer = this.el.querySelector(".bloc-carte .th-slider");
    var _self = this;

    if (sliderContainer) {
        var navButtons = this.el.querySelectorAll(".nav button");

        this.slider = new thSlider(sliderContainer, {
            scrollListener: true,
            draggable: true,
            scrollModeMaxWidth: 1024,
            oninit: function (slider) {
                thSliderTools.onInitNav(slider, navButtons);
            },
            onchange: function (slider) {
                if (slider.state.current !== _self.currentSlide) {
                    $(slider.state.items[_self.currentSlide]).removeClass("active");
                    $(slider.state.items[slider.state.current]).addClass("active");
                    var marker_id = $(slider.state.items[slider.state.current]).data("id");

                    if (_self.markers[marker_id] && !_self.mapLock) {
                        _self.flyToMarker(_self.markers[marker_id], marker_id);
                    }

                    _self.currentSlide = slider.state.current;
                }

                thSliderTools.onChangeNavIgnoreLastItemIn(slider, navButtons);
            }
        });
    }
};