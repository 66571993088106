// Dans le "bloc" sit à propos, on ajoute un bouton pour voir plus ou moins si le texte à dépassé une hauteur de 400px
var sitProposHeight = $('.single-sit .text');
var btnShowMorePropos = $('.single-sit .btn-view-more');

sitProposHeight.each(function () {
    if ($(this).height() >= 120) {
        $(this).parent().addClass('view-more-active');

        btnShowMorePropos.on('click', function () {
            $(this).prev().toggleClass('toggle-height');
        });
    }
});

// PAGE SINGLE SIT
function callbackMapSingleSIT(macarte) {
    macarte.zoomControl.setPosition('topright');
    macarte.setZoom(14);
    if (isTouchDevice()) {
        macarte.dragging.disable();
    }
}


var calendarDateFormat = 'DD/MM/YYYY';
var calendarDateLanguage = ($('html').attr('lang')).substring(0,2);
var startDate = moment(new Date()).format(calendarDateFormat);

var status_class = {
    0: 'nous-consulter',
    1: 'libre',
    2: 'ferme',
    3: 'complet',
    4: 'passe',
};

// calendrier des disponibilités
var $rangeCalendarAlwaysOpen = $('#calendar-range');
if ($rangeCalendarAlwaysOpen.length > 0) {
    $rangeCalendarAlwaysOpen.dateRangePicker({
        inline: true,
        container: '#calendar-range',
        alwaysOpen: true,
        stickyMonths: true,
        singleMonth: false,
        showShortcuts: false,
        showTopbar: false,
        startOfWeek: 'monday',
        customArrowPrevSymbol: '<span class="icon-ico-arrow-left"></span>',
        customArrowNextSymbol: '<span class="icon-ico-arrow-right"></span>',
        format: calendarDateFormat,
        language: calendarDateLanguage,
        beforeShowDay: function (t) {

            var valid = false;

            var day = (t.getDate());
            if(day < 10) {
                day = '0'+day;
            }
            var month = (t.getMonth()+1);
            if(month < 10) {
                month = '0'+month;
            }
            var current_date = t.getFullYear() + '-' + month + '-' + day;

            var status = sit_dispos[current_date];
            if(typeof status != 'undefined') {
                status = status.status;
                if(status == 0 || status == 1) {
                    valid = true;
                }
            } else {
                status = 4;
            }

            var tooltip_text = $('.sit-dispos [data-status=' + status + ']').text();

            var valid = valid;
            var _class = status_class[status];
            var _tooltip = tooltip_text ? tooltip_text : '';
            return [valid, _class, _tooltip];
        }
    });
}

function initGalerieSITSlider() {
    var sliders = document.querySelectorAll('.sit-galerie .th-slider:not(.no-autoload)');
    for (i = 0; i != sliders.length; i++) {
        var slider = sliders[i];
        initGalerie(slider);
    }


    function initGalerie(slider) {


        var parent = slider.parentNode;
        while (!parent.className.match(/th-slider-wrapper/)) {
            parent = parent.parentNode;
        }

        var navButtons = parent.querySelectorAll('.nav-buttons button');
        var dotsWrapper = parent.querySelectorAll('.nav-dots');
        var counterWrapper = parent.querySelector('.counter-wrapper');

        var reverse = 0;
        if (slider.className.match(/reverse/) && !isTabletPortraitOrSmalller()) {
            reverse = 1;
        }

        var s = new thSlider(slider, {
            draggable: true,
            rtl: reverse,
            scrollListener: true,
            scrollModeMaxWidth: 1024,
            oninit: function (slider) {

                if (navButtons.length === 2) {
                    thSliderTools.onInitNav(slider, navButtons);
                }

                if (dotsWrapper) {
                    thSliderTools.onInitDots(slider, dotsWrapper[0]);
                }


                if (counterWrapper) {
                    thSliderTools.onInitCounter(slider, counterWrapper);
                }

            },
            onchange: function (slider) {

                if (navButtons.length > 0) {
                    thSliderTools.onChangeNavIgnoreLastItemIn(slider, navButtons);
                }
                if (dotsWrapper) {
                    thSliderTools.onChangeDots(slider, dotsWrapper[0]);
                }
                if (counterWrapper) {
                    thSliderTools.onChangeCounter(slider, counterWrapper);
                }
            }
        });

    }
}


function isSliderGalerieSITReady() {
    if (typeof thSlider === "undefined") {
        setTimeout(isSliderGalerieSITReady, 1000);
        return false;
    }
    initGalerieSITSlider();
}

isSliderGalerieSITReady();


function initHorairesSITSlider() {
    var sliders = document.querySelectorAll('.sit-horaires .th-slider:not(.no-autoload)');
    for (i = 0; i != sliders.length; i++) {
        var slider = sliders[i];
        initHoraires(slider);
    }


    function initHoraires(slider) {


        var parent = slider.parentNode;
        while (!parent.className.match(/th-slider-wrapper/)) {
            parent = parent.parentNode;
        }

        var navButtons = parent.querySelectorAll('.nav-buttons button');
        var dotsWrapper = parent.querySelectorAll('.nav-dots');
        var counterWrapper = parent.querySelector('.counter-wrapper');

        var reverse = 0;
        if (slider.className.match(/reverse/) && !isTabletPortraitOrSmalller()) {
            reverse = 1;
        }

        var s = new thSlider(slider, {
            draggable: true,
            rtl: reverse,
            scrollListener: true,
            scrollModeMaxWidth: 1024,
            oninit: function (slider) {

                if (navButtons.length === 2) {
                    thSliderTools.onInitNav(slider, navButtons);
                }

                if (dotsWrapper) {
                    thSliderTools.onInitDots(slider, dotsWrapper[0]);
                }


                if (counterWrapper) {
                    thSliderTools.onInitCounter(slider, counterWrapper);
                }

            },
            onchange: function (slider) {

                if (navButtons.length > 0) {
                    thSliderTools.onChangeNavIgnoreLastItemIn(slider, navButtons);
                }
                if (dotsWrapper) {
                    thSliderTools.onChangeDots(slider, dotsWrapper[0]);
                }
                if (counterWrapper) {
                    thSliderTools.onChangeCounter(slider, counterWrapper);
                }
            }
        });

    }
}

initHorairesSITSlider();


// Smooth scroll vers la carte - au click sur la miniature de la carte dans la barre sit

$(document).ready(function () {

    $("#carte-anchor").on('click', function (event) {
        if (this.hash !== "") {
            event.preventDefault();
            var hash = this.hash;

            $('html, body').animate({
                scrollTop: $(hash).offset().top
            }, 800, function () {
                window.location.hash = hash;
            });
        }
    });
});
