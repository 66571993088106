// Ensuite si le width des ancres est inférieur à la taille du header, on cache la flèche et le overflow

var children = $('.related-pages-menu').children();

if (children) {
    var totalWidth = 0;

    children.each(function () {
        totalWidth += $(this).outerWidth();
    });

    if ($('.related-pages-menu')) {
        if (totalWidth <= $('.related-pages-menu').outerWidth()) {
            $('.related-pages .container .guide').css('display', 'none');
        }
    }
}


/* Initialisation du slider de la carte */

(function () {

    var sliders = document.querySelectorAll('.related-pages .th-slider');
    for (i = 0; i != sliders.length; i++) {
        var slider = sliders[i];
        console.log(isTabletPortraitOrSmalller());
        if (isTabletPortraitOrSmalller()) {
            initSlider(slider);
        }
    }

    function initSlider(slider) {
        var parent = slider.parentNode;

        var navButtons = parent.querySelectorAll('.nav-buttons button');
        var dotsWrapper = parent.querySelectorAll('.nav-dots');

        var s = new thSlider(slider, {
            draggable: true,
            scrollListener: true,
            scrollModeMaxWidth: '1024',
            oninit: function (slider) {

                if (navButtons.length === 2) {
                    thSliderTools.onInitNav(slider, navButtons);
                }

                if (dotsWrapper) {
                    thSliderTools.onInitDots(slider, dotsWrapper[0]);
                }

            },
            onchange: function (slider) {
                if (navButtons.length > 0) {
                    thSliderTools.onChangeNavIgnoreLastItemIn(slider, navButtons);
                }
                if (dotsWrapper) {
                    thSliderTools.onChangeDots(slider, dotsWrapper[0]);
                }
            }
        });

    }


})();
