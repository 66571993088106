//spécificités listing Agenda
parseCardAgendaClickOverlay();

// fm.callbacks.afterUpdate = function () {
//     parseCardAgendaClickOverlay();
//     fm.map.update();
// };

// OVERLAY MAP
function callbackMapOverlayMap(macarte) {
    macarte.setZoom(14);
    macarte.zoomControl.setPosition('topright');
}



// listing SIT classique - uniquement une map en overlay
var listingSitDefault = new thListingManager({
    selectors:{
        listing: '.listing-sit-default-js',
        mapoverlay:'#map-listing',
        nb_filters: '#filtres-actifs'
    }
});

// listing SIT ITI - map dans listing +  map dans overlay en mobile
var listingSitITI = new thListingManager({
    selectors:{
        listing: '.page-listing-itineraire',
        map: '#map-listing-iti',
        mapoverlay:'#map-listing',
        nb_filters: '#filtres-actifs'
    },
    mapOptions: {
        onlyDesktop: true,
    },
    mapOverlayOptions: {
        onlyMobile: true,
    }
});

// listing SIT FMA - uniquement une map en overlay + overlay sur les cards
var listingSitFMA = new thListingManager({
    selectors:{
        listing: '.page-listing-agenda',
        mapoverlay:'#map-listing',
        nb_filters: '#filtres-actifs'
    },
    afterUpdate:function () {
        parseCardAgendaClickOverlay();
    }
});

/* A l'ouverture de l'overlay, on charge la carte */
// cette fonction doit $etre déclarée avant le th_overlay.init()
th_overlay.addCallbackOpen(function (overlayId) {
    if (overlayId == 'overlay-map') {
        setTimeout(function () {
            if(listingSitDefault.loaded) {
                listingSitDefault.initMapOverlay();
            }
            if(listingSitITI.loaded) {
                listingSitITI.initMapOverlay();
            }
            if(listingSitFMA.loaded) {
                listingSitFMA.initMapOverlay();
            }
        }, 250);
    }
});

// PAGE LISTING SIT
function callbackMapPageListingLEI(macarte) {
    macarte.setZoom(14);
    macarte.zoomControl.setPosition('topright');
}




