/* Scroll into view  */

var ongletsRelation = $(".bloc-relation .container-onglets label");
ongletsRelation.each(function () {
    $(this).on('click', function (e) {
        e.preventDefault();
        var radioId = e.target.getAttribute('for');
        var radio = document.querySelector('#'+radioId);
        radio.click();
    })
});

/* Au click sur le label on fait un prevent default pour éviter que la page "saute" vers le haut à cause du fait que les input sont positionnées dans la partie haute du bloc */


/* Initialisation du slider */
(function () {

    var sliders = document.querySelectorAll('.bloc-relation .th-slider:not(.no-autoload)');
    for (i = 0; i != sliders.length; i++) {
        var slider = sliders[i];
        console.log('slider', slider);
        initSlider(slider);
    }


    function initSlider(slider) {

        var parent = slider.parentNode;
        console.log('parent', parent);


        var navButtons = parent.querySelectorAll('.nav-buttons button');
        var dotsWrapper = parent.querySelectorAll('.nav-dots');


        var reverse = 0;
        if (slider.className.match(/reverse/) && !isTabletPortraitOrSmalller()) {
            reverse = 1;
        }

        var s = new thSlider(slider, {
            draggable: true,
            rtl: reverse,
            scrollListener: true,
            scrollModeMaxWidth: 1280,
            oninit: function (slider) {

                console.log("buttons", navButtons);

                if (navButtons.length === 2) {
                    thSliderTools.onInitNav(slider, navButtons);
                }

                if (dotsWrapper) {
                    thSliderTools.onInitDots(slider, dotsWrapper[0]);
                }

            },
            onchange: function (slider) {

                if (navButtons.length > 0) {
                    thSliderTools.onChangeNavIgnoreLastItemIn(slider, navButtons);
                }
                if (dotsWrapper) {
                    thSliderTools.onChangeDots(slider, dotsWrapper[0]);
                }
            }
        });

    }

})();