// listing experiences - uniquement une map en overlay
var listingCPT = new thListingManager({
    selectors:{
        listing: '.post-type-archive',
        cardsContainer: '.wrapper-cards',
        mapoverlay:'#map-listing',
        nb_filters: '#filtres-actifs'
    }
});

/* A l'ouverture de l'overlay, on charge la carte */
// cette fonction doit $etre déclarée avant le th_overlay.init()
th_overlay.addCallbackOpen(function (overlayId) {
    if (overlayId == 'overlay-map') {
        setTimeout(function () {
            if(listingCPT.loaded) {
                listingCPT.initMapOverlay();
            }
        }, 250);
    }
});