var lastscrolltop = 0;
var lastIsDirTop = 0;
var lastScrollTop = 0, delta = 5;
var headerHeight = $("#barre-navigation").outerHeight();
var ancres = $('.ancres');


window.addEventListener('scroll', function () {
    var st = $(this).scrollTop();
});


(function () {

    // On calcule le width de chaque ancre afin de faire la somme et savoir quel est la vraie largeur des ancres.
    // Ensuite si le width des ancres est inférieur à la taille du header, on cache la flèche et le overflow

    var children = $('.ancres .track-scroll').children();

    if (children) {
        var totalWidth = 0;

        children.each(function () {
            totalWidth += $(this).outerWidth();
        });

        if ($('.ancres .track-scroll')) {
            if (totalWidth > $('.ancres .track-scroll').outerWidth() + 3) {
                $('.ancres').addClass('has-slider');
            }
        }

    }



    var sliders = document.querySelectorAll('.ancres .th-slider:not(.no-autoload)');
    for (i = 0; i != sliders.length; i++) {
        var slider = sliders[i];
        initSlider(slider);
    }


    function initSlider(slider) {
        var parent = slider.parentNode;
        while (!parent.className.match(/th-slider-wrapper/)) {
            parent = parent.parentNode;
        }

        var navButtons = parent.querySelectorAll('.nav-buttons button');
        var dotsWrapper = parent.querySelectorAll('.nav-dots');


        var reverse = 0;
        if (slider.className.match(/reverse/) && !isTabletPortraitOrSmalller()) {
            reverse = 1;
        }

        var s = new thSlider(slider, {
            draggable: true,
            rtl: reverse,
            scrollListener: true,
            scrollModeMaxWidth: '1024',
            oninit: function (slider) {

                if (navButtons.length === 2) {
                    thSliderTools.onInitNav(slider, navButtons);
                }

                if (dotsWrapper) {
                    thSliderTools.onInitDots(slider, dotsWrapper[0]);
                }

            },
            onchange: function (slider) {

                if (navButtons.length > 0) {
                    thSliderTools.onChangeNavIgnoreLastItemIn(slider, navButtons);
                }
                if (dotsWrapper) {
                    thSliderTools.onChangeDots(slider, dotsWrapper[0]);
                }
            }
        });

    }

})();




