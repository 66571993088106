class evin {
    selector = {
        overlay : ".js-resa-elloha",
        onglets : "[data-resa-onglet]",
        contents : "[data-resa-content]"
    }

    nodes = {
        overlay: null,
        onglets: null,
        contents: null
    }

    constructor() {
        this.nodes.overlay = document.querySelector(this.selector.overlay);
        if (this.nodes.overlay){
            this.nodes.onglets = this.nodes.overlay.querySelectorAll(this.selector.onglets);
            this.nodes.contents = this.nodes.overlay.querySelectorAll(this.selector.contents);
        }else{
            return false;
        }

        if (this.nodes.onglets.length === 0 || this.nodes.contents.length === 0){
            return false;
        }

        this.listen();
    }

    listen(){
        this.nodes.onglets.forEach((node, key) => {
            node.addEventListener('click', (e) => {
                e.preventDefault();
                this.change(key);
            })
        });
    }

    change(key){
        this.reset();
        this.nodes.onglets[key].classList.add('--active');
        this.nodes.contents[key].classList.add('--active');
    }

    reset(){
        this.nodes.onglets.forEach((node) => {
            node.classList.remove('--active');
        });
        this.nodes.contents.forEach((node) => {
            node.classList.remove('--active');
        });
    }

}

const alerteEvin = new evin();