var btnMenu = $('.btn-menu');
var barreDeNavigation = $('#barre-navigation');
var overlayMenu = $('.overlay-menu');

btnMenu.on('click', function () {
    barreDeNavigation.toggleClass("is-open");
});

if (overlayMenu.hasClass("open")) {
    barreDeNavigation.toggleClass("is-open");
}