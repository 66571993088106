// ouverture de l'overlay des cards agenda sur le listingfma
function parseCardAgendaClickOverlay(){
    $('#container-listing-agenda .card:not(.parsed)').on('click',function (e) {
        e.preventDefault();

        var elem = $(this);

        var sit_url = elem.attr('href')+'?overlay';
        var overlay_id = 'overlay-agenda';

        var date = moment($('.dtstart',elem).attr('datetime'),"YYYY-MM-DD").format("Do MMMM");
        var h_from = $($('.dtstart .info-date span',elem).get(0)).text();
        var h_to = $($('.dtstart .info-date span',elem).get(1)).text();

        $('#' + overlay_id + ' .content-sit').html('');
        $('#' + overlay_id + ' .loader').removeClass('hide');
        $('#' + overlay_id + ' .bottom-part').addClass('hide');

        $.get(sit_url, function (responseHTML) {
            $('#' + overlay_id + ' .loader').addClass('hide');
            $('#' + overlay_id + ' .content-sit').html(responseHTML);
            $('#' + overlay_id + ' .content-sit .time').html($('time',elem).html());
            $('#' + overlay_id + ' .lower-bar .link-js').attr('href',elem.attr('href'));
            $('#' + overlay_id + ' .bottom-part').removeClass('hide');
            $('#' + overlay_id + ' .date-js').text(date);

            if(!h_from && !h_to) {
                $('#' + overlay_id + ' .hours-js-from-to').addClass('hide');
            } else if(h_from && !h_to) {
                $('#' + overlay_id + ' .hours-js-from').removeClass('hide');
                $('#' + overlay_id + ' .hour-js-from').text(h_from);
            } else if(h_from && h_to) {
                $('#' + overlay_id + ' .hours-js-from-to').removeClass('hide');
                $('#' + overlay_id + ' .hour-js-from').text(h_from);
                $('#' + overlay_id + ' .hour-js-to').text(h_to);
            }
        });

        th_overlay.open(overlay_id);


    }).addClass('parsed');
}

parseCardAgendaClickOverlay();


/* Initialisation du slider de la carte */

(function () {

    var sliders = document.querySelectorAll('.overlay-map .th-slider');
    for (i = 0; i != sliders.length; i++) {
        var slider = sliders[i];
        console.log(isTabletPortraitOrSmalller());
        if (isTabletPortraitOrSmalller()) {
            initSlider(slider);
        }
    }

    function initSlider(slider) {
        var parent = slider.parentNode;

        var navButtons = parent.querySelectorAll('.nav-buttons button');
        var dotsWrapper = parent.querySelectorAll('.nav-dots');

        var s = new thSlider(slider, {
            draggable: true,
            scrollListener: true,
            scrollModeMaxWidth: '1024',
            oninit: function (slider) {

                if (navButtons.length === 2) {
                    thSliderTools.onInitNav(slider, navButtons);
                }

                if (dotsWrapper) {
                    thSliderTools.onInitDots(slider, dotsWrapper[0]);
                }

            },
            onchange: function (slider) {
                if (navButtons.length > 0) {
                    thSliderTools.onChangeNavIgnoreLastItemIn(slider, navButtons);
                }
                if (dotsWrapper) {
                    thSliderTools.onChangeDots(slider, dotsWrapper[0]);
                }
            }
        });

    }


})();
