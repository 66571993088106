(function () {

    var sliders = document.querySelectorAll('.bloc-carte .th-slider');
    for (i = 0; i != sliders.length; i++) {
        var slider = sliders[i];
        initSlider(slider);
    }

    function initSlider(slider) {
        var parent = slider.parentNode;
        while (!parent.className.match(/bloc-carte/)) {
            parent = parent.parentNode;
        }

        var navButtons = parent.querySelectorAll('.nav-buttons button');
        var counterWrapper = parent.querySelector('.counter-wrapper');

        var s = new thSlider(slider, {
            draggable: true,
            scrollListener: true,
            scrollModeMaxWidth: '1024',
            oninit: function (slider) {

                if (navButtons.length === 2) {
                    thSliderTools.onInitNav(slider, navButtons);
                }
                if (counterWrapper) {
                    thSliderTools.onInitCounter(slider, counterWrapper);
                }

            },
            onchange: function (slider) {
                if (navButtons.length > 0) {
                    thSliderTools.onChangeNavIgnoreLastItemIn(slider, navButtons);
                }
                if (counterWrapper) {
                    thSliderTools.onChangeCounter(slider, counterWrapper);
                }
            }
        });

    }


})();


// PAGE LISTING SIT
function callbackMapBlockSlider(macarte) {
    console.log("Ma carte", macarte);
    macarte.zoomControl.setPosition('topright');

    var markers = [];
    $('#results .card', $(macarte._container).parents('.bloc')).each(function () {
        var id = this.getAttribute('data-id');

        var latlng = {lat: this.getAttribute('data-lat') * 1, lng: this.getAttribute('data-lng') * 1};
        var marker = th_maps.createMarker(macarte, latlng, 'default');

        macarte.on("click", function (e) {
            marker.setIcon(th_maps.markersIcons['default']);
        });


        // Lorsqu'on fait un hover sur une card, on change l'icône du marqueur
        $(this).on({
            mouseenter: function () {
                marker.setIcon(th_maps.markersIcons['hover']);
            },
            mouseleave: function () {
                marker.setIcon(th_maps.markersIcons['default']);
            }
        });


        markers.push(marker);
    });
    var group = new L.featureGroup(markers);
    macarte.fitBounds(group.getBounds());
    if (isTouchDevice()) {
        macarte.dragging.disable();
    }
}